
.settings-card {
    /* border: solid 1px #ccc; */
    /* border-radius: 20px; */
    /* height: 100%; */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.settings-header {
    /* border: solid 1px blue; */
    /* padding: 20px; */
    /* text-align: center; */
    background-color: #F3f3f3;  
    font-weight: 600;
    border-bottom: solid 1px #ccc;
    height: 50px;
    font-size: 14px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    opacity: .9;
    /* border: solid 1px black; */
    border-radius: 4px;
    align-items: center;

}
.settings-body {
    display: flex;
    flex-direction: column;
    justify-content: center;

    /* align-items: center;s */
    /* border: solid 1px black; */
    flex-grow: 4;
    /* height: 100%; */
    border: solid 1px #f3f3f3;
    font-size: 12px;
}
.settings-body div{
   flex-grow: 1;
   /* display: flex; */
   text-align: center;
   width: 100%;
   align-items: center;
   /* align-items: space-around; */
   padding: 10px;
   /* border: solid 1px #f3f3f3; */
   /* background-color: red; */
}

.settings-body div:hover{
    background-color: #F3f3f3;
    cursor: pointer;
 }
 
.selected {
    background-color:  #ECF3F9;
    font-size: 13px;
    font-weight: 600;
    /* font-weight: bold; */
    /* border-left: solid 1px #ccc; */
    /* border-right: solid 1px #ccc; */
    /* border-bottom: solid 1px #ccc; */

}
.selected:hover {
    background-color: white;
}

#facebook-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%; /* set to desired percentage */
}
#social-icon {
    font-size: 14px;
    /* width:100%; */
    /* height:30%; */
}

  
  #test {
    display: flex;
    /* justify-content: space-between; this will put some space between the text and the image */
    align-items: center;
  }
.category-option {
    display:flex;
    justify-content: center;
    align-items: center;
}

#settings-container {
    display: flex;
    flex-direction: column;
    /* border: solid 1px black; */
    border: solid 1px #ccc;
    /* border: 1px red solid; */
    /* margin-top: 100px; */
    width: 100%;
    /* height: 100vh; */
}
/* #update-button { */
    /* height: 10000px; */
    /* border: solid 1px black; */
    /* height: 20%; */
/* } */
#update-button button{
    width: 100%;

    /* height: 10px; */
    /* heigh */
    /* padding: 10px; */
}

#card-group {
    display: flex;
    flex-direction: row;
    /* border: solid 1px black; */
    flex-grow: 1;
    /* justify-content: space-between; */
    width: 100%;
    border-radius: 10px;
    height: 80%;
}