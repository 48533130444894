#main-profile-container {
    color: black;
    /* border: solid 3px green; */
    height:100%;
    width:100%;
    display: flex;
    flex-direction: row;
    padding: 10px;
    background-color: white;
}
#general-form {
    /* border: solid 1px black; */
    width:80%;
    height:100%;
    padding: 10px;  

}
#profile-photo {
    /* border: solid 1px black; */
    width: 20%;
    height:100%;
    padding: 10x;
}
#profile-image {
    margin-top: 15px;
    height: 90vh;
    /* border: solid 1px black; */
    border-radius: 20px;
    background-color: #fafafa;
    border: solid 1px #ccc;
    display: flex;
    flex-direction: column;
    padding: 5px;
   
}
#profile-image-div {
    margin-top: 10px;
}

#edit-image {
    background-color: #00247d;
    color: white;
    height: 3vh;
    width: 5vw;
    opacity: .6;
    border-radius: 10px;
    margin-top: 15px;
}
#profile-image-name {
    /* border: solid 1px #ccc; */
    padding: 5px;
    color: black;
    font-size: 15px;
    padding: 10px;
}
.profile-info {
    padding: 5px;
    color: black;
    font-size: 15px;
    padding: 10px;
}