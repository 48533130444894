#configSettingsContainer {
  /* border: solid 4px darkBlue; */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: space-around;
  align-content: space-around;
  /* width: 100vw; */
  /* height: 100vh; */
  max-width: 45vw;
  background-color: #FFFAFA;
  align-items: space-between;
  min-width: 45vw;
  width: 45vw;
  /* height: 80%; */
  /* border: solid 3px red; */
}

#configSettingsContainer div {
  /* border: dashed 3px blue; */
  /* flex-grow: 1; */
}

#universal-preview-container {
  /* border: dashed 3px purple; */
  /* margin-top: 200px; */
  /* flex-grow: 1; */
  /* height: 45vh; */
  /* width: 50vw; */
  /* padding: 10px; */
  height: 100%;
  opacity: .95;
}

#screenshot-preview-container {
  /* border: solid 3px green; */
  /* flex-grow: 1; */
  /* height: 45vh; */
  /* width: 50vw; */
  /* padding: 10px; */
  height: 100%;
  opacity: .95;
  /* border: solid 1px black; */

}

#screenshot-image {
  max-width:100%;
    /* max-height: 50vh; */
  /* border: solid 1px black; */
  width: 100%;
  height: 100%;
  min-width:100%;
    min-height:100%;
}

#ul-image {
  width: 100%;
  height: 100%;
  /* margin-top: 200px; */
}

#screenshot_image {
  height: 90%;
  width: 90%;
  border: solid 1px #ccc;
  box-shadow: 1px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

#screenshot_image:hover {
  content: url('./drag-drop.png');
  cursor: pointer;
}

#ul_image {
  height: 90%;
  width: 90%;
  /* margin-bottom: 10px; */
  max-height: 50vh;

  border: solid 1px #ccc;
  box-shadow: 1px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 10px 5px 0px rgba(0, 0, 0, 0.75);

}



.center {
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  border: 1px solid #ccc;
  width: 90%;
}

.wave {
  width: 2px;
  height: 100px;
  background: linear-gradient(45deg, #E9DCC9, #fff);
  /* margin: 10px; */
  animation: wave 6s linear infinite;
  border-radius: 10px;
}

.wave:nth-child(2) {
  animation-delay: 0.3s;
}

.wave:nth-child(3) {
  animation-delay: 0.2s;
}

.wave:nth-child(4) {
  animation-delay: 0.3s;
}

.wave:nth-child(5) {
  animation-delay: 0.4s;
}

.wave:nth-child(6) {
  animation-delay: 0.5s;
}

.wave:nth-child(7) {
  animation-delay: 0.6s;
}

.wave:nth-child(8) {
  animation-delay: 0.7s;
}

.wave:nth-child(9) {
  animation-delay: 0.8s;
}

.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

#loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90% !important;
  /* border: 1px red solid; */
  /* border: solid 1px #ccc; */
  /* width: 50%; */
}

.drag-drop-zone {

  /* border: 2px dashed #cccccc; */
  display: flex;
  /* align-items: center;
    justify-content: center; */
  /* text-align: center; */
}

.drag-drop-zone.highlight {
  /* border: 2px dashed #999999; */
  background-color: #FFFAFA;
  /* padding: 20px; */
  /* z-index: 10; */
  /* background: url('https://static.vecteezy.com/system/resources/previews/002/285/250/original/download-and-upload-icon-set-free-vector.jpg'); */
  /* background: url('https://www.pngarts.com/files/2/Upload-Transparent-Image.png'); */
}

.file-input {
  display: none;
}