#card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: solid 1px black; */
    margin-top: 100px;
    height: 40vh;
    flex-direction: column;
}
#card-container img {
    width: 400px;
}
#card-container p {
    font-family:'Times New Roman', Times, serif;
    font-size: 10px;
    font-weight: bold;
}

/* #PaymentForm {
    width: 1000px */
/* } */
#PaymentForm .rccs {
    width: 400px;
    height: 250px;
  }
  #PaymentForm .rccs__card  {
      width: 400px;
      height: 250px;
      /* background-color: black; */
  }
  #PaymentForm .rccs-cvc-color {
      background-color: black;
  }
  #PaymentForm .rccs__card--unknown {
      background-color: red;
  }