#settingsContainer {
    background-color: #FAFAFA;
    width: 100vw;
    height: 100vh;
    color: black;
    display: flex;
    padding: 20px;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 3px green; */
}