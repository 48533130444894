#card-form-container {
    height: 100%;
    padding: 30px;
    /* border: solid 4px black; */
    background-color: white;
    /* background-color: red; */
    display: flex;
    justify-content: center;
}
#card-form-group {
    /* border: solid 4px black; */
    background-color: #fafafa;
    height: 80vh;
    border-radius: 10px;
    padding: 25px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
#card-form-group input{
    height: 7vh;
    font-size: 18px;
    line-height: 10px;
    border-radius: 4px;
    padding: 10px;
    color: grey;
    border: solid 1px #ccc;
}
#card-form-input-group {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom:50px;
}
#card-form-input-group label {
    text-align: left;
    margin-bottom: 5px;
    margin-left: 5px;
    font-size: 11px;
    color: darkGrey;    
}
#card-form-input-group-bottom {
    display: flex;
    flex-direction: row;
    /* border: solid 10px green; */
    width: 100%;
}
#card-form-input-group-bottom #card-form-input-group {
    flex-grow: 1;
    /* border: solid 10px black; */
}
#edit-option {
    /* border: solid 1px black; */
    display: flex;
    justify-content: flex-end;
}
#edit-option button{
    width: 100px; 
    height:30px;   
    margin-left: 5px;
    background-color: #00247d;
    opacity:.6;
    color: white;
    border-radius: 5px;
    margin-right: 10px;
}
#modal {
    border: solid 1px #ccc;
    z-index: 4;
    width: 80vw;
    height: 80vh;
    position: absolute;
    background-color: #fafafa;
    border-radius: 20px;
    display: none;
    flex-grow:1;
    flex-direction: column;
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  
#modal-header {
    /* border: solid 1px black; */
    justify-content: flex-start;
    flex-grow: 1;
    display: flex;
}
#modal-header img {
    width: 35px;
    height: 35px;
    margin-left: 4px;
    margin-top: 4px;

}
#modal-body {
    flex-grow: 50;  
}
#modal-body-image img {
    margin-top: 30px;
    width: 500px;
    opacity: .5;
}
#modal-body-image p {
    font-size: 12px;
    margin-top: 40px;
    font-weight: bold;
    color: darkGrey;
}
#phone-entry {
    /* margin-top: 20px; */
    width: 30%;
    height: 20px;
    border-radius: 20px;
    border: solid 1px #ccc;
    font-size: 10px;
    text-align: center;
}
#input-group-sms {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
#sms-button {
    /* background-color: blue; */
    font-size: 30px;
    margin-top: 25px;
    margin-left: 10px;
}
#card-pic {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-top: 90px;
    padding: 30px;
    /* width: 400px; */
}



/* 



#modal {
    z-index: 4;
    width: 40vw;
    height: 60vh;
    position: absolute;
    background-color: #ffffff;
    border-radius: 20px;
    display: none;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    -webkit-animation: fadeInUp 0.5s ease-out both;
    animation: fadeInUp 0.5s ease-out both;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

#modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#modal-header img {
    width: 35px;
    height: 35px;
}

#modal-close {
    cursor: pointer;
    font-size: 1.5em;
}

#modal-body {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#modal-body-image img {
    width: 80%;
    margin-bottom: 20px;
    border-radius: 10px;
}

#modal-body-image p {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

#input-group-sms {
    display: flex;
    justify-content: center;
    align-items: center;
}

#phone-entry {
    width: 70%;
    height: 40px;
    border-radius: 20px;
    border: solid 1px #ccc;
    font-size: 18px;
    padding: 10px;
}

#sms-button {
    font-size: 24px;
    margin-left: 10px;
    color: #007BFF;
    cursor: pointer;
} */
