#brandSettingsContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 25px;
  overflow: scroll;
  background-color: #fafafa;
  border: solid 1px #ccc;
  max-width: 55%;
}

#brandSettingInput {
  height: 70px;
  font-size: 16px;
  padding: 10px;
  color: black;
  border: none;
  text-transform: capitalize;
}


#brandSettingInput:focus {
  border: none;
}

#spacer {
  height: 20px;
}

#internal {
  border-radius: 20px;
  width: 100%;
  /* border: 1px green solid; */
  display: flex;
  flex-direction: column;
}

#search-bar {
  /* border: 1px red solid; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#search-button {
  width: 15%;
  border-radius: 10px;
  height: 70px;
  border: none;
  color: black;
  font-size: 16px;
  /* font-weight: bold; */
}

#search-button:hover {
  cursor: pointer;
  background-color: #E5E4E2;
  color: black;
  font-weight: bolder;
}

#background-container {
  padding: 10px;
  border-radius: 10px;
  /* background-color: #f0f8ff; */
}

#logo-container {
  border: solid 1px #ccc;
  padding: 10px;
  border-radius: 10px;
  background-color: #FFFAFA;
}

#card-container {
  /* border: solid 2px #ccc; */
  margin-top: 0px;
  margin-bottom: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  opacity: 0;
  transition: opacity 0.6s ease-in;
  /* border: 1px red solid; */
}

#hello {
  border: solid 1px #ccc;
  border-radius: 10px;
  width: 100%;
  height: 10vh;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  color: black;
  font-family: sans-serif;
  letter-spacing: 2px;

}

#update-preview {
  border: solid 1px #ccc;
  background-color: lightGrey;
  /* font-weight: bold;  */
  color: black;
  text-align: center;
  border-radius: 10px;
  width: 100%;
  height: 10vh;
  padding: 10px;
  padding-left: 100px;
  padding-right: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: black;
  font-family: sans-serif;
  letter-spacing: 2px;

}

#card-container.show {
  opacity: 1;
}

#update-button {
  width: 100%;
  border: none;
  letter-spacing: 2px;
}

#update-button button {
  padding: 15px;
  border: none;
  letter-spacing: 2px;
}

#update-button button:hover {
  padding: 15px;
  border: none;
  letter-spacing: 2px;
  background-color: #f3f3f3;
  cursor: pointer;
}

#brandSettingInput:focus {
  outline: none;
}

#menu-bar {
  display: flex;
  flex-direction: row;
  /* border: 1px red solid; */
}

#menu-bar div {
  flex-grow: 1;
  padding: 30px;
  border: solid 1px #ccc;
  margin-bottom: 10px;
  border-radius: 10px;
  margin-right: 5px;
}

#menu-bar div:hover {
  background-color: #FFFFF7;
}


.swing-in-top-fwd {
  -webkit-animation: swing-in-top-fwd 0.7s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
  animation: swing-in-top-fwd 0.7s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

.active {
  background-color: #E5E4E2;
  font-weight: bolder;

}

/* #slider {
    padding: 17px;
} */

#organizational-prompt {
  border: solid 1px black;
  flex-grow: 1;
  width: 100%;
  padding: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}



.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.settings-menu-bar {
  /* border: 1px red solid !important; */
  font-size: 16px;
  /* font-weight: bolder; */
}

.settings-menu-bar:hover {
  cursor: pointer;


  background-color: #E5E4E2 !important;
  color: black;
}

#update-preview:hover:enabled {
  cursor: pointer;
  background-color: #697CEA;
  color: white;
}

#brandSettingInputContainer {
  /* border: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* #brandSettingInputContainer label {
  flex-grow: 1;
  background-color: black;
  height: 20px;

} */

#displayAsButtonInnerDiv {
  /* border: 1px red solid; */
  display: flex;
  width: 60%;
  justify-content: space-around;
}

.toggleDiv {
  border: 1px #ccc solid;
  display: flex;
  width: 45%;
  /* width: 75%; */
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.toggleDiv>p {
  font-size: 12px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  /* border: 1px red solid; */
  margin: 0 10px 0 0;
  width: 50%;
}