#customUrl {
    background-color: #ccc;
    margin-left: 90px;
    border-radius: 20px; 
    width: 60vw;
    height:10vh;
}
/* button {
    font-size: 40px;
} */
#searchContainer {
    position: absolute;
    bottom: 0;
    left: 0 ;
    opacity: 1;
    margin-bottom: 60px;
}
#customContainer {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-image: url("https://img.freepik.com/free-vector/abstract-painted-background_23-2148546162.jpg?w=2000");
    /* background-position: center; */

}
#searchContainer:hover {
    opacity: 1;
}
#search-box {
  opacity: 1;
  /* border: solid 1px #FAFAFA; */
  opacity: .6;
  /* padding: 3px; */
}
#searchBox {
  -webkit-animation: pulsate-bck 0.5s ease-in-out infinite both;
  animation: pulsate-bck .5s ease-in-out infinite both;  
  background-color: #22a6b3;
  /* background-color: white; */
  /* border: dashed 10px lightBlue; */
  background-image: url("https://cdn-icons-png.flaticon.com/512/122/122932.png");
  background-size: cover;
  background-position: center;
  opacity: 1;
  padding: 15px;
  /* opacity: 1; */
}
@-webkit-keyframes pulsate-bck {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes pulsate-bck {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
img {
    width: 2000px;
    height: 200px;
    border: solid 1px white;
}

.center {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
  }
  .wave {
    width: 5px;
    height: 100px;
    background: linear-gradient(45deg, red, #fff);
    margin: 10px;
    animation: wave 1s linear infinite;
    border-radius: 20px;
  }
  .wave:nth-child(2) {
    animation-delay: 0.1s;
  }
  .wave:nth-child(3) {
    animation-delay: 0.2s;
  }
  .wave:nth-child(4) {
    animation-delay: 0.3s;
  }
  .wave:nth-child(5) {
    animation-delay: 0.4s;
  }
  .wave:nth-child(6) {
    animation-delay: 0.5s;
  }
  .wave:nth-child(7) {
    animation-delay: 0.6s;
  }
  .wave:nth-child(8) {
    animation-delay: 0.7s;
  }
  .wave:nth-child(9) {
    animation-delay: 0.8s;
  }
  .wave:nth-child(10) {
    animation-delay: 0.9s;
  }
  
  @keyframes wave {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  
  



  .search-box{
    width: fit-content;
    height: fit-content;
    opacity: .3;
    /* position: relative; */
  }
  #new-btn-search {
    background-image: url("https://cdn-icons-png.flaticon.com/512/122/122932.png");
    background-size: cover;
    background-position: center;
    opacity: .5;
  }
  .search-box:hover {
    opacity: 1;
    color: white;
  }
  
  .input-search{
    height: 50px;
    width: 50px;
    border-style: none;
    padding: 10px;
    font-size: 18px;
    letter-spacing: 2px;
    outline: none;
    border-radius: 25px;
    transition: all .5s ease-in-out;
    background-color: #22a6b3;
    padding-right: 40px;
    color:grey ;
    opacity: 1;
    /* background-image: url('https://i.pinimg.com/474x/b9/67/82/b96782d0ab0ea492dabcf4a0f00698d2.jpg'); */

  }
  .input-search::placeholder{
    color:rgba(255,255,255,.5);
    font-size: 18px;
    letter-spacing: 2px;
    opacity: 1;

    font-weight: 100;
  }
  .btn-search{
    width: 50px;
    height: 50px;
    border-style: none;
    font-size: 20px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    color:grey ;
    background-color:transparent;
    pointer-events: painted;  
    opacity: 1;

  }
  .btn-search:focus ~ .input-search{
    width: 100vw;
    border-radius: 0px;
    background-color: grey;
    border-bottom:1px solid rgba(255,255,255,.5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
    color: white;
  }
  .input-search:focus{
    width: 100vw;
    border-radius: 0px;
    background-color: grey;
    color: white;
    border-bottom:1px solid rgba(255,255,255,.5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
  }
  
  #loginButton {
    position: absolute;
    right: 0;
    height: 30vh;
    /* border: solid 1px black; */
    width: 50vw;
    cursor: pointer;

  }
  #test {
    /* border: solid 30px black; */
    height:100vh;
    width: 100vw;
  }
  #test img {
    height: 100%;
    width: 100%;
  }

  #bg-img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    border: 1px red solid;
  }

  #screenshot-image { 
    max-width:100%;
    max-height:100%;
    /* border: solid 1px black; */
    min-width:100%;
    min-height:100%;
}