#styled-burger {
  -webkit-animation: pulsate-bck 0.5s ease-in-out infinite both;
  animation: pulsate-bck 0.5s ease-in-out infinite both;
  width: 70px;
  height: 70px;
  opacity: .01;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2px;
  /* background-color: #FAFAFA; */
  border-radius: 10px;

}

#styled-burger span {
  background-color: white;
  width: 90%;
  height: 5%;

}

#styled-burger:hover {
  opacity: 1;
  /* background-color: red; */
  /* color: white; */
  /* border: solid 1px black; */
  border: none;
}

#hamburger-menu {
  /* border: 1px black solid !important; */
  z-index: 100;
  background: transparent;
}

@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}