.carousel-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: solid 1px black; */
  }
  
  .carousel-container {
    width: 100%;
    /* padding: 10px; */
    /* max-width: 800px; */
    /* margin: 0 auto; */
    /* margin-top: 400px; */
    /* height: 250px; */
    border: solid 1px black;
    background-color: #CCC;
    /* border: solid 100px black; */
    border-radius: 20px;
    justify-content: center;
    align-items: center;

  }
  #carousel-for-sure {
      border: solid 100px black;
  }
  .image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .imageItem  {
    width: 200px;
    height: 200px;
    border-radius: 20px;
    /* width: 100%;
    height: 100%; */
/* ?    object-fit: cover; */
    padding: 10px;
    background-color: #fafafa;
  }
  .imageItem:hover {
    cursor: pointer;
  }
  
  .button-container {
    margin-top: 20px;
  }
  
  #slider {
    /* border: solid 10px black; */
    padding: 10px;
    border: solid 1px black;
    background-color: red;
    height: 150px;
    background-size: cover;
  }
  /* .carousel-initialized {
    /* border: solid 4px black; */
    /* padding: 40px; */

/* div .carousel-initialized {
  padding: 2000px;
} */
.imageItem {
  border: #ccc solid 1px;
  width: 300px;
  height: 300px;
  height: auto;
  opacity: .8;
}
.imageItem:hover {

  opacity: 1;
}
#brandSettingInput {
  background-color: #F3F3F3;
  width: 100%;
  margin-top: 10px;
  margin-bottom:10px;
  border-radius: 10px;
  font-size: 16px;
  /* text-align: right; */
  padding-left: 2rem;
}
#brandSettingURLInput {
  background-color: #F3F3F3;
  width: 100%;
  margin-top: 10px;
  margin-bottom:10px;
  border-radius: 10px;
  height: 70px; 
  font-size: 16px;
  padding:10px;
  color: black;
  border: none;
  width: 84%;
  padding-left: 2rem;

}
#brandSettingURLInput:focus {
  outline: none;
}
.slick-slide img{
  width: 190px;
  height: 190px;
}
.slick-prev {
  color: black;
}
.slick-next {
  color: black;
}

#text {
  display: none;
}
#slider-container {
  padding: 17px;
}
#text {
  display: none;
}